import * as React from "react"
import Header from "../../../components/Header";
import '../../../templates/styles.scss';

// markup
const ZoomPage = () => {

  const country = "en";
  const language = "en";

  React.useEffect(() => {
    window.location = "https://www.thealbany.org.uk/shows/the-digital-home-live-experience/";
  });

  return (
    <>
      <Header language={language} country={country} urlParts={["/en/zoom_signup"]} />
      <div className="home-wrapper" style={{textAlign: "center"}}>
              
      </div>
    </>    
  )
}

export default ZoomPage
